// Keep the right tab open in preview
const store = {}

const tabSwitcher = (links, texts, toggleOpen) => {
  return function() {
    const $root = $(this);
    const active = "active"
    const hidden = "hidden"
    const future = "future"
    const $links = $root.find(links)
    const $texts = $root.find(texts)
    const $toggleOpen = $root.find(toggleOpen)
    const $animator = $texts.parent()
    let oldPos = -1;
    let menuOpen = true;

    const calcHeight = function(element) {
      return Array.prototype.reduce.call(element.childNodes, function(p, c) {return p + (c.offsetHeight || 0);}, 0) + 'px';
    }

    const updateHeights = function() {
      $texts.each(function() {
        let element = $(this)[0];
        let height = calcHeight(element)
        element.style.height = height;
      })
    }

    const enableAnimation = function() {
      setTimeout(function() {
        $animator.addClass('animated')
      }, 10);
    }

    const toggleMenu = function() {
      menuOpen = !menuOpen;
      if (menuOpen) {
        $links.parent().addClass('open');
      } else {
        $links.parent().removeClass('open');
      }
    }

    const switchTo = function(n) {
      $links.removeClass(active).eq(n).addClass(active)
      $texts.removeClass(future)
      $texts.eq(oldPos).addClass(hidden)
      $texts.eq(n).addClass(future).removeClass(hidden)
      store[$root.attr('id')] = n;
      oldPos = n;
      menuOpen = true;
      toggleMenu();
    }

    $links.each(function(n) {
      $(this).click((e) => {
        e.preventDefault();
        switchTo(n);
      })
    })

    $toggleOpen.click((e) => {
      e.preventDefault();
      toggleMenu()
    })

    const lastPos = store[$root.attr('id')] || 0;
    updateHeights();
    $texts.addClass(hidden)
    switchTo(lastPos);
    enableAnimation();
    window.addEventListener('resize', updateHeights);
  }
}

export default tabSwitcher;
