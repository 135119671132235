const scroller = (buttonSel) => {
  return function() {
    const $scroller = $(this);
    const $prev = $scroller.prev(buttonSel)
    const $next = $scroller.next(buttonSel)
    const $items = $scroller.find('> *')
    const itemCount = $items.find('> *').length;
    let pos = 0;
    const setTransform = () => {
      let w = $items.children().eq(pos)[0].offsetLeft;
      $items.css('transform', `translate3d(${-w}px,0,0)`)
    }
    const prev = () => {
      if (pos === 0) {
        pos = Math.max(itemCount -1, 0);
      } else {
        pos = Math.max(pos - 1, 0);
      }
      setTransform()
    }

    const next = () => {
      if (pos === itemCount - 1) {
        pos = 0;
      } else {
        pos = Math.min(pos + 1, itemCount - 1);
      }
      setTransform()
    }

    $next.click(next);
    $prev.click(prev);
    window.addEventListener('resize', setTransform);
    setTransform();
  }
}

export default scroller;
