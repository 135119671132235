import tabSwitcher from './tabSwitcher'
import scroller from './scroller'

const elementBehaviours = () => {
  $('form .input.with_errors:first').each(function() {
    $(this)[0].scrollIntoView();
  });
  $('#scroll_to_element:first').each(function() {
    $(this)[0].scrollIntoView();
  });
  $('.element.tabs').each(tabSwitcher('.headers a.tab-link', '.texts .text', '.toggle-open'));
  $('.element.team4_columns .scroller').each(scroller('.scroll'));

  $('.element.header').each(function() {
    const $element = $(this);
    const $button = $element.find("button.menu")
    const $content = $element.find(".menu-content")
    const $open = $button.find('.open')
    const $close = $button.find('.close')
    let isOpen = false;

    const open = () => {
      isOpen = true;
      $content.show();
      $close.show();
      $open.hide();
    }

    const close = () => {
      isOpen = false;
      $content.hide();
      $close.hide();
      $open.show();
    } 

    close();

    $button.click((e) => {
      e.preventDefault();
      e.stopPropagation();
      isOpen ? close() : open();
      if (isOpen) {
        $('body').one('click', (e) => {
          close();
        });
      }
    })
  });

  $('.element.hero_with_right_image .text.fade-in-lines').each(function() {
    const $text = $(this);
  });

  $('form input[type=submit].recaptcha').each(function() {
    const $button = $(this)
    const action = $button.data('action') || 'submit';
    const siteKey = $('#recaptcha_key').data('site') || ''
    if (siteKey.length == 0) {
      console.warn("Recaptcha: could not find site key. No checks will be performed.")
      return
    }
    $button.click(function(e) {
      e.preventDefault();
      grecaptcha.ready(function() {
        grecaptcha.execute(siteKey, { action: action }).then(function(token) {
          let $hidden = $('<input />').attr('type', 'hidden').attr('name', 'recaptcha_token').val(token)
          $button.closest('form').append($hidden).submit();
        })
      })
    });
  });
}

export default elementBehaviours
